import { MetaFunction } from '@remix-run/react'

import { createPageName, NotFoundFallback } from '~/common'

export const handle = {
  pageName: 'Not Found',
}

export const meta: MetaFunction = () => {
  return [{ title: createPageName('Not Found') }]
}

export default function NotFoundPage() {
  return <NotFoundFallback />
}
